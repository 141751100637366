import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import LinkD from '../components/linkd';
import Seo from '../components/seo';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const IndexPage = () => {

  return (
      <>
      <Seo title="Home" description="Werner Digital Technology Home"/>
        <Paper sx={{padding: '0 0 1em'}}>
          <Box display="flex" alignItems="flex-start">
            <Box ml={2}>
              <StaticImage alt="" width={160} loading="eager" aria-labelledby="site-name" src="../images/wernerdigital-hosted.png" />
            </Box>
            <Box mt={6} ml={1}>
              <Typography variant='h4' component='h1' id="site-name">
                Werner Digital Technology, Inc.
              </Typography>
              <Typography sx={{marginLeft: '1em'}}>
                Tools and building blocks
                <br />for modern business.
              </Typography>
            </Box>
          </Box>

          <Box mt={3} ml={1}>
          <Typography mb={1}> Welcome to our website! </Typography> We develop clean and affordable software
          for public and private cloud applications.  We can also help your
          development projects by providing scaffolding and/or consulting to get
          you over any initial roadblocks and provide a secure foundation for future changes.

          <Typography mt={1}> Thanks for visiting, please
            <LinkD to='/contact' sx={{marginLeft: 1, marginRight: 1}}> contact us </LinkD>
            with any questions or comments.
          </Typography>
          </Box>

        </Paper>

      </>
  )
}

export default IndexPage
